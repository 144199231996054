import React, { generatePath, Link, NavLink } from 'react-router-dom'
import { useContext, useState } from 'react'
import { faArrowRightFromBracket, faUser, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContext, UserLogoutContext } from '../../contexts/user'
import { LanguageContext } from '../../contexts/language'
import { ADMINISTER, READ, WRITE } from '../../constants/permissions'
import LanguagePopup from './LanguagePopup'
import './index.css'

const Menu = () => {
  const user = useContext(UserContext)
  const onLogOut = useContext(UserLogoutContext)
  const canRead = user && user.permissions.includes(READ)
  const canWrite = user && user.permissions.includes(WRITE)
  const isAdmin = user && user.permissions.includes(ADMINISTER)
  const { locale, t } = useContext(LanguageContext)
  const [showLanguagePopup, setShowLanguagePopup] = useState(false)
  const onLanguageChange = () => setShowLanguagePopup(true)

  return (
    <div className="menu">
      <div className="menu__section">
        <div className="menu__item">
          <Link to={generatePath('/:locale/zone', { locale })}>
            <img src="/logo-white.svg" alt="logo" style={{ width: 35 }} />
          </Link>
        </div>
        {user && (
          <div className="menu__item">
            <NavLink
              to={generatePath('/:locale/zone', { locale })}
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              {t('top-menu.zone')}
            </NavLink>
          </div>
        )}
        {user && (
          <div className="menu__item">
            <NavLink
              to={generatePath('/:locale/points', { locale })}
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              {t('top-menu.points')}
            </NavLink>
          </div>
        )}
        {user && (
          <div className="menu__item">
            <NavLink
              to={generatePath('/:locale/distance', { locale })}
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              {t('top-menu.distance')}
            </NavLink>
          </div>
        )}
        {user && (
          <div className="menu__item">
            <NavLink
              to={generatePath('/:locale/converter', { locale })}
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              {t('top-menu.converter')}
            </NavLink>
          </div>
        )}
        {canWrite && (
          <div className="menu__item">
            <NavLink
              to={generatePath('/:locale/beacons', { locale })}
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              {t('top-menu.beacons')}
            </NavLink>
          </div>
        )}
        {canRead && (
          <div className="menu__item">
            <NavLink
              to={generatePath('/:locale/info', { locale })}
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              {t('top-menu.info')}
            </NavLink>
          </div>
        )}
        {isAdmin && (
          <div className="menu__item">
            <NavLink
              to={generatePath('/:locale/admin/users', { locale })}
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              {t('top-menu.users')}
            </NavLink>
          </div>
        )}
      </div>
      <div className="menu__section">
        <div className="menu__item">
          <div className="menu__sign-out" title={t('top-menu.language')} onClick={onLanguageChange}>
            <FontAwesomeIcon icon={faGlobe} />
          </div>
        </div>
        {user && (
          <>
            <div className="menu__item">
              <div className="menu__user" title={user.name}>
                <FontAwesomeIcon icon={faUser} />
              </div>
            </div>
            <div className="menu__item">
              <div className="menu__sign-out" title={t('top-menu.exit')} onClick={onLogOut}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </div>
            </div>
          </>
        )}
      </div>
      {showLanguagePopup && <LanguagePopup onClose={() => setShowLanguagePopup(false)} />}
    </div>
  )
}

export default Menu
