import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useMatch } from 'react-router'
import getTranslations from '../utils/language/getTranslations'
import getTranslation from '../utils/language/getTranslation'

export const LanguageContext = React.createContext(undefined)

export const LanguageProvider = ({ children }) => {
  const { params: { locale } } = useMatch('/:locale/*') || { params: { locale: 'uk' } }
  const translations = useMemo(() => getTranslations(), [])
  const t = useCallback((key, vars) => getTranslation({ key, vars, translations, locale }), [translations, locale])
  const providerValue = useMemo(() => ({ locale, t }), [locale, t])

  if (locale !== 'uk' && locale !== 'en') return <Navigate to="/uk/points" />

  return (
    <LanguageContext.Provider value={providerValue}>
      {children}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
