import React, { useState, useEffect, useRef, useContext } from 'react'
import { toast } from 'react-toastify'
import ColorPicker from '@uiw/react-color-sketch'
import { LanguageContext } from '../../contexts/language'
import DmsToDd from '../../utils/dmsToDd'
import Map from '../Common/Map'
import checkLatLng from '../../utils/checkLatLng'
import MapForm from '../Common/MapForm'
import Input from '../Common/Input'
import fetch from '../../utils/fetch'
import getNearestBeacon from '../../utils/getNearestBeacon'
import getDistance from '../../utils/getDistance'
import getBearing from '../../utils/getBearing'
import CopyToClipboardPopup from '../Common/CopyToClipboardPopup'
import './index.css'

const MapZone = () => {
  const { locale, t } = useContext(LanguageContext)
  const colorPickerRef = useRef(null)
  const [showBeacons, setShowBeacons] = useState(false)
  const [beacons, setBeacons] = useState(undefined)
  const [input, setInput] = useState('')
  const [targetCoordinates, setTargetCoordinates] = useState(undefined)
  const [distanceLine, setDistanceLine] = useState([])
  const [distanceText, setDistanceText] = useState('')
  const [areasToDraw, setAreasToDraw] = useState([])
  const [lineColor, setLineColor] = useState('#000000')
  const [lineThickness, setLineThickness] = useState(2)
  const [showColorPicker, setShowColorPicker] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowColorPicker(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [colorPickerRef])

  const onChange = (e) => {
    setInput(e.currentTarget.value)
  }

  const onChangeLineThickness = (e) => {
    setLineThickness(e.currentTarget.value)
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    let error
    const areas = input
      .split('/')
      .filter((val) => val.trim().length > 0)
      .map((lineData) => lineData
        .split(/[\n;–-]/)
        .filter((val) => val.trim().length > 0)
        .map((value) => {
          const [lat, lng] = (/[°˚NSEW]/.test(value) ? DmsToDd(value) : value).trim().split(/[^-\d.]+/)
          if (!checkLatLng(lat, lng)) {
            error = t('error.coordinates-wrong-format')
          }
          return { lat: parseFloat(lat), lng: parseFloat(lng) }
        }),
      )

    if (error) {
      toast.warning(error)
    } else {
      // const line = coordinates.length > 2 ? [...coordinates, coordinates[0]] : coordinates
      setAreasToDraw(areas)
      setTargetCoordinates(undefined)
      setDistanceLine([])
      setDistanceText('')
    }
  }

  const onFormClear = () => {
    setInput('')
    setAreasToDraw([])
    setTargetCoordinates(undefined)
    setDistanceLine([])
    setDistanceText('')
  }

  const onClickMap = (e) => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    setTargetCoordinates({ lat, lng })
    const nearestBeacon = getNearestBeacon({ lat, lng }, beacons)
    if (nearestBeacon) {
      const distance = Math.round(getDistance(nearestBeacon, { lat, lng }) * 10) / 10
      const bearingTranslationKey = getBearing(nearestBeacon, { lat, lng }, true)
      const bearing = t(bearingTranslationKey)
      setDistanceText(t('distance-finder.result-text', { distance, bearing, beacon: nearestBeacon.title }))
      setDistanceLine([
        { lat: parseFloat(lat), lng: parseFloat(lng) },
        nearestBeacon,
      ])
    }
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/beacons`).then((response) => {
      const data = response.map(({ lat, lng, ...rest }) => ({ lat: parseFloat(lat), lng: parseFloat(lng), ...rest }))
      setBeacons(data)
    }).catch(() => {})
  }, [setBeacons])

  return (
    <div className="map-zone">
      {showColorPicker && (
        <div className="map-zone__color-picker" ref={colorPickerRef}>
          <ColorPicker
            color={lineColor}
            onChange={(color) => setLineColor(color.hex)}
          />
        </div>
      )}
      <div className="map-zone__form">
        <MapForm
          title={t('areas.title')}
          buttonSubmitTitle={t('areas.button-show.title')}
          onChange={onChange}
          onSubmit={onFormSubmit}
          onClear={onFormClear}
          data={input}
          isDisabled={input.length === 0}
          textRows={5}
          onChangeShowPoints={() => setShowBeacons(!showBeacons)}
          showChangeShowPoints
          showPoints={showBeacons}
        />
      </div>
      <div className="map-zone__map">
        <Map
          markers={showBeacons ? beacons : []}
          targetMarker={targetCoordinates}
          onClick={onClickMap}
          linesToDraw={[...areasToDraw, distanceLine]}
          lineColor={lineColor}
          lineThickness={lineThickness}
          defaultCenter={areasToDraw[0] && areasToDraw[0][0]}
          containerHeight="calc(100vh - 320px)"
          cursor="crosshair"
          language={locale}
        />
        <div className="map-zone__show-color" onClick={() => setShowColorPicker(true)}>
          {t('areas.lines-color-picker')}
          <div className="map-zone__show-color-button" style={{ backgroundColor: lineColor }} />
        </div>
        <div className="map-zone__line-thickness">
          {t('areas.lines-thickness')}
          <Input value={lineThickness} onChange={onChangeLineThickness} className="map-zone__line-thickness-input" />
        </div>
      </div>
      {distanceText && (
        <CopyToClipboardPopup text={distanceText} onClose={() => setDistanceText('')} />
      )}
    </div>
  )
}

export default React.memo(MapZone)
