import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Item from './Item'
import './index.css'

const LanguagePopup = ({ onClose }) => (
  <Popup
    defaultOpen
    modal
    onClose={onClose}
    overlayStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
  >
    {(close) => (
      <div className="language-popup">
        <div className="language-popup__close" onClick={close}>
          <FontAwesomeIcon icon={faTimes} color="#555555" />
        </div>
        <Item lang="uk" />
        <Item lang="en" />
      </div>
    )}
  </Popup>
)

LanguagePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default LanguagePopup
