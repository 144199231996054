import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Map from '../Common/Map'
import BeaconPopup from './BeaconPopup'
import fetch from '../../utils/fetch'
import MapForm from '../Common/MapForm'
import { LanguageContext } from '../../contexts/language'
import DmsToDd from '../../utils/dmsToDd'
import './index.css'

const BeaconsEditor = () => {
  const { locale, t } = useContext(LanguageContext)
  const [input, setInput] = useState('')
  const [newBeacon, setNewBeacon] = useState(undefined)
  const [beacons, setBeacons] = useState(undefined)

  const onChange = (e) => {
    setInput(e.currentTarget.value)
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    const coordinates = /[°˚NSEW]/.test(input) ? DmsToDd(input) : input
    const [lat, lng] = coordinates.split(/[^-\d.]+/)

    if (!(Math.abs(parseFloat(lat)) <= 90 && Math.abs(parseFloat(lng)) <= 180)) {
      toast.warning(t('error.coordinates-wrong-format'))
      return
    }
    setNewBeacon({ lat, lng })
  }

  const onFormClear = () => {
    setInput('')
    setNewBeacon(undefined)
  }

  const onClickMap = (e) => {
    setNewBeacon({ lat: e.latLng.lat(), lng: e.latLng.lng() })
  }

  const onAddBeacon = (title) => {
    fetch(
      `${process.env.REACT_APP_API_HOST}/beacons`, {
        method: 'POST',
        body: JSON.stringify({ ...newBeacon, title }),
      }).then(({ lat, lng, ...rest }) => {
      setBeacons([...beacons, { lat: parseFloat(lat), lng: parseFloat(lng), ...rest }])
    }).catch(({ error }) => {
      toast.error(error)
    })
  }

  const onDeleteBeacon = (id) => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(t('beacons.delete-confirm'))) {
      return
    }
    fetch(
      `${process.env.REACT_APP_API_HOST}/beacons`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
      }).then(() => {
      setBeacons(beacons.filter((item) => item.id !== id))
    }).catch(({ error }) => {
      toast.error(error)
    })
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/beacons`).then((response) => {
      const data = response.map(({ lat, lng, ...rest }) => ({ lat: parseFloat(lat), lng: parseFloat(lng), ...rest }))
      setBeacons(data)
    }).catch(() => {})
  }, [setBeacons])

  return (
    <div className="beacons-editor">
      <div className="beacons-editor__form">
        <MapForm
          title={t('beacons.title')}
          buttonSubmitTitle={t('beacons.button-add.title')}
          onChange={onChange}
          onSubmit={onFormSubmit}
          onClear={onFormClear}
          data={input}
          isDisabled={input.length === 0}
        />
      </div>
      <Map
        markers={beacons}
        onClick={onClickMap}
        onDeleteMarker={onDeleteBeacon}
        cursor="crosshair"
        language={locale}
        isActive
      />
      {newBeacon && (
        <BeaconPopup
          onClose={() => setNewBeacon(undefined)}
          beacon={newBeacon}
          onAdd={onAddBeacon}
        />
      )}
    </div>
  )
}

export default React.memo(BeaconsEditor)
