import React, { useContext } from 'react'
import { generatePath, Navigate } from 'react-router-dom'
import { LanguageContext } from '../../contexts/language'

const Home = () => {
  const { locale } = useContext(LanguageContext)
  return <Navigate to={generatePath('/:locale/points', { locale })} />
}

export default Home
