import React from 'react'
import './index.css'

const Page404 = () => (
  <div className="page-404">
    Not Found - 404
  </div>
)

export default Page404
