import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { LanguageContext } from '../../contexts/language'
import Map from '../Common/Map'
import fetch from '../../utils/fetch'
import getDistance from '../../utils/getDistance'
import getBearing from '../../utils/getBearing'
import getNearestBeacon from '../../utils/getNearestBeacon'
import DmsToDd from '../../utils/dmsToDd'
import CopyToClipboardPopup from '../Common/CopyToClipboardPopup'
import MapForm from '../Common/MapForm'
import './index.css'

const DistanceFinder = () => {
  const { locale, t } = useContext(LanguageContext)
  const [beacons, setBeacons] = useState(undefined)
  const [targetCoordinates, setTargetCoordinates] = useState('')
  const [result, setResult] = useState('')
  const [lineToDraw, setLineToDraw] = useState([])

  const onChangeCoordinates = (e) => {
    setTargetCoordinates(e.currentTarget.value)
  }

  const onAddCoordinates = ({ lat, lng }) => {
    const nearestBeacon = getNearestBeacon({ lat, lng }, beacons)
    if (nearestBeacon) {
      const distance = Math.round(getDistance(nearestBeacon, { lat, lng }) * 10) / 10
      const bearingTranslationKey = getBearing(nearestBeacon, { lat, lng }, true)
      const bearing = t(bearingTranslationKey)
      setResult(t('distance-finder.result-text', { distance, bearing, beacon: nearestBeacon.title }))
      setLineToDraw([
        { lat: parseFloat(lat), lng: parseFloat(lng) },
        nearestBeacon,
      ])
    }
  }

  const onClickMap = (e) => {
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    setTargetCoordinates(`${lat}, ${lng}`)
    onAddCoordinates({ lat, lng })
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    const coordinates = /[°˚NSEW]/.test(targetCoordinates) ? DmsToDd(targetCoordinates) : targetCoordinates
    const [lat, lng] = coordinates.split(/[^-\d.]+/)

    if (!(Math.abs(parseFloat(lat)) <= 90 && Math.abs(parseFloat(lng)) <= 180)) {
      toast.warning(t('error.coordinates-wrong-format'))
      return
    }
    onAddCoordinates({ lat, lng })
  }

  const onFormClear = () => {
    setTargetCoordinates('')
    setResult('')
    setLineToDraw([])
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/beacons`).then((response) => {
      const data = response.map(({ lat, lng, ...rest }) => ({ lat: parseFloat(lat), lng: parseFloat(lng), ...rest }))
      setBeacons(data)
    }).catch(() => {})
  }, [setBeacons])

  return (
    <div className="distance-finder">
      <div className="distance-finder__form">
        <MapForm
          title={t('distance-finder.title')}
          buttonSubmitTitle={t('distance-finder.button-show.title')}
          onChange={onChangeCoordinates}
          onSubmit={onFormSubmit}
          onClear={onFormClear}
          data={targetCoordinates}
          isDisabled={targetCoordinates.length === 0}
        />
      </div>
      <Map
        markers={beacons}
        onClick={onClickMap}
        targetMarker={lineToDraw && lineToDraw[0]}
        linesToDraw={[lineToDraw]}
        cursor="crosshair"
        language={locale}
      />
      {result && (
        <CopyToClipboardPopup text={result} onClose={() => setResult('')} />
      )}
    </div>
  )
}

export default React.memo(DistanceFinder)
