import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { LanguageContext } from '../../../contexts/language'
import fetch from '../../../utils/fetch'
import Textarea from '../../Common/Textarea'
import Button from '../../Common/Button'
import { GREEN } from '../../../constants/buttons'
import './index.css'

const ImportPopup = ({ children, onImport }) => {
  const { t } = useContext(LanguageContext)
  const [data, setData] = useState('')

  const onChangeData = (e) => {
    setData(e.currentTarget.value)
  }

  const onClickAdd = (close) => {
    fetch(
      `${process.env.REACT_APP_API_HOST}/info/import`, {
        method: 'POST',
        body: JSON.stringify({ data }),
      }).then(() => {
      setData('')
      onImport()
      close()
    }).catch(({ error }) => {
      toast.error(error)
    })
  }

  return (
    <Popup
      trigger={children}
      closeOnDocumentClick={false}
      modal
      overlayStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
    >
      {(close) => (
        <div className="info-import-popup">
          <div className="info-import-popup__close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} color="#555555" />
          </div>
          <Textarea
            className="info-import-popup__textarea"
            placeholder={t('info.import-popup.placeholder')}
            onChange={onChangeData}
            rows={20}
          >
            {data}
          </Textarea>
          <Button
            type={GREEN}
            onClick={() => onClickAdd(close)}
            isDisabled={data.length === 0}
          >
            {t('info.import-popup.add')}
          </Button>
        </div>
      )}
    </Popup>
  )
}

ImportPopup.propTypes = {
  children: PropTypes.node.isRequired,
  onImport: PropTypes.func,
}

ImportPopup.defaultProps = {
  onImport: () => {},
}

export default ImportPopup
