import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { LanguageContext } from '../../../../contexts/language'
import './index.css'

const Item = ({ lang }) => {
  const { locale, t } = useContext(LanguageContext)
  const location = useLocation()
  const changeLanguage = () => {
    window.location.href = location.pathname.replace(new RegExp(`^/${locale}/`), `/${lang}/`)
  }
  return (
    <div
      className={`language-popup-item ${locale === lang ? 'language-popup-item_active' : ''}`}
      onClick={locale !== lang ? changeLanguage : undefined}
    >
      {t(`language-popup.${lang}`)}
    </div>
  )
}

Item.propTypes = {
  lang: PropTypes.string.isRequired,
}

export default Item
