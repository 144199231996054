import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { LanguageContext } from '../../contexts/language'
import ListItem from './ListItem'
import fetch from '../../utils/fetch'
import { UserContext } from '../../contexts/user'
import Input from '../Common/Input'
import ImportPopup from './ImportPopup'
import Button from '../Common/Button'
import { GREEN, RED, YELLOW } from '../../constants/buttons'
import { ADMINISTER, READ, WRITE } from '../../constants/permissions'
import './index.css'

const Info = () => {
  const { t } = useContext(LanguageContext)
  const [info, setInfo] = useState([])
  const [showForm, setShowForm] = useState(true)
  const [importCount, setImportCount] = useState(0)
  const [currCallsign, setCurrCallsign] = useState('')
  const [currCallsignTlg, setCurrCallsignTlg] = useState('')
  const [currCorrespondent, setCurrCorrespondent] = useState('')
  const [currComment, setCurrComment] = useState('')
  const [currUnsure, setCurrUnsure] = useState(false)
  const user = useContext(UserContext)
  const isReadingAllowed = user.permissions.includes(READ)
  const isAdmin = user.permissions.includes(ADMINISTER)
  const isEditingAllowed = isAdmin || user.permissions.includes(WRITE)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/info`).then((response) => {
      setInfo(response)
    }).catch(() => {})
  }, [importCount])

  const onChangeCallsign = (e) => {
    setCurrCallsign(e.currentTarget.value)
  }

  const onChangeCallsignTlg = (e) => {
    setCurrCallsignTlg(e.currentTarget.value)
  }

  const onChangeCorrespondent = (e) => {
    setCurrCorrespondent(e.currentTarget.value)
  }

  const onChangeComment = (e) => {
    setCurrComment(e.currentTarget.value)
  }

  const onChangeUnsure = (e) => {
    setCurrUnsure(e.target.checked)
  }

  const onClickCancel = () => {
    setCurrCorrespondent('')
    setCurrCallsign('')
    setCurrCallsignTlg('')
    setCurrComment('')
    setCurrUnsure(false)
  }

  const onDelete = (id) => {
    if (!window.confirm(t('info.on-delete-confirm'))) {
      return
    }
    fetch(
      `${process.env.REACT_APP_API_HOST}/info`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
      })
      .then(() => {
        setInfo([...info.filter((item) => item.id !== id)])
      }).catch(() => {})
  }

  const onDeleteAll = () => {
    if (!window.confirm(t('info.on-delete-all-confirm'))) {
      return
    }
    fetch(
      `${process.env.REACT_APP_API_HOST}/info/all`, {
        method: 'DELETE',
      }).then(() => { setInfo([]) }).catch(() => {})
  }

  const onUpdate = (item) => {
    setInfo([...info.map((currItem) => (currItem.id === item.id ? item : currItem))])
  }

  const onClickAdd = () => {
    fetch(
      `${process.env.REACT_APP_API_HOST}/info`, {
        method: 'POST',
        body: JSON.stringify({
          callsign: currCallsign,
          callsign_tlg: currCallsignTlg,
          correspondent: currCorrespondent,
          comment: currComment,
          unsure: currUnsure,
        }),
      }).then((response) => {
      setInfo([response, ...info])
      setCurrCallsign('')
      setCurrCallsignTlg('')
      setCurrCorrespondent('')
      setCurrComment('')
      setCurrUnsure(false)
    }).catch(() => {})
  }

  const onImport = () => setImportCount(importCount + 1)

  const filterInfo = (item) => (
    item.callsign.toLowerCase().includes(currCallsign.toLowerCase())
    && item.callsign_tlg.toLowerCase().includes(currCallsignTlg.toLowerCase())
    && item.correspondent.toLowerCase().includes(currCorrespondent.toLowerCase())
    && item.comment.toLowerCase().includes(currComment.toLowerCase())
    && (currUnsure ? item.unsure === 1 : true)
  )

  const sortInfo = (a, b) => (b.added_at - a.added_at)

  if (!isReadingAllowed) {
    return <Navigate to="/" />
  }

  const inputs = [
    { title: t('info.form.correspondent'), value: currCorrespondent, onChange: onChangeCorrespondent },
    { title: t('info.form.telephone'), value: currCallsign, onChange: onChangeCallsign },
    { title: t('info.form.telegraph'), value: currCallsignTlg, onChange: onChangeCallsignTlg },
    { title: t('info.form.comment'), value: currComment, onChange: onChangeComment },
  ]
  const headers = inputs.map(({ title }) => title)

  return (
    <div className="info">
      {!showForm && (
        <div className="info__add-open" title={t('info.open-form-button.title')}>
          <Button onClick={() => setShowForm(true)}>
            <FontAwesomeIcon icon={faPlus} color="#555555" />
          </Button>
        </div>
      )}
      {showForm && (
        <div className="info__add">
          <div className="info__add-close">
            <FontAwesomeIcon icon={faTimes} color="#555555" onClick={() => setShowForm(false)} />
          </div>
          {inputs.map(({ title, onChange, value }) => (
            <Input
              key={title}
              placeholder={title}
              className="info__add-input"
              type="text"
              onChange={onChange}
              value={value}
            />
          ))}
          <label className="info__add-checkbox-label" htmlFor="unsure">
            <input
              className="info__add-checkbox"
              type="checkbox"
              id="unsure"
              name="unsure"
              onChange={onChangeUnsure}
              checked={currUnsure}
            />
            {t('info.form.unsure.title')}
          </label>
          <div className="info__buttons">
            <div className="info__button">
              <Button
                onClick={onClickCancel}
                isDisabled={!currCallsign && !currCallsignTlg && !currCorrespondent && !currComment && !currUnsure}
              >
                {t('info.form.clear-button.title')}
              </Button>
            </div>
            {isEditingAllowed && (
              <>
                <div className="info__button">
                  <Button
                    onClick={onClickAdd}
                    isDisabled={!currCallsign && !currCallsignTlg && !currCorrespondent && !currComment}
                    type={GREEN}
                  >
                    {t('info.form.add-button.title')}
                  </Button>
                </div>
                {isAdmin && (
                  <>
                    <div className="info__button">
                      <ImportPopup onImport={onImport}>
                        <Button type={YELLOW}>
                          {t('info.form.import-button.title')}
                        </Button>
                      </ImportPopup>
                    </div>
                    <div className="info__button">
                      <Button type={RED} onClick={onDeleteAll}>
                        {t('info.form.delete-all-button.title')}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className="info__list-headers">
        {headers.map((title) => <div className="info__list-headers-item" key={title}>{title}</div>)}
      </div>
      <div className="info__list">
        {info.filter(filterInfo).sort(sortInfo).map((item) => (
          <ListItem {...item} onDelete={onDelete} onUpdate={onUpdate} key={item.id} />
        ))}
      </div>
    </div>
  )
}

export default Info
